// state.po.itemID not null that mean modify order items
// state.po.currentID null that mean add new order.
// back to order list need clear state.po.currentID and state.po.itemID and state.po.order_no

import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  Upload,
  message,
  Timeline,
  Divider,
  Space,
} from "antd";

import axios from "axios";
import { useStateContext } from "../../Store/StateProvider";
import { poAction } from "../../Reducers/po_reducer";
import { useHistory } from "react-router-dom";

import { scanBarcode } from "../../Utils/barCodeScanner";

export default function AddOrderItem(props: any) {
  const [form] = Form.useForm();
  let history = useHistory();
  let [pro, setPro] = useState(null);
  let [stock, setStock] = useState(null);
  let [addbtnLoading, setAddBtnLoading] = useState(true);
  let [state, dispatch] = useStateContext();
  let data = props.data;
  let modalVisiable = props.modalVisiable;
  let setModalVisiable = props.setModalVisiable;
  const inputRef = useRef<any>(null);

  let [sltCode, setSltCOde] = useState([]); // for code load.
  let [cusCode, setCusCOde] = useState([]); // for cus load.
  let [csName, setCsName] = useState("");
  let [itemQty, setItemQty] = useState("");
  let [itemUnit, setItemUNIT] = useState("");

  // for goods list modal.
  let [showCSGoodList, setShowCsGoodsList] = useState(false);
  let [goodsData, setGoodsData] = useState([] as Array<any>);
  let [originGoodsData, setOriginGoodsData] = useState([] as Array<any>);

  // for goods list modal. end

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current!.focus({
          cursor: "start",
        });
      }
      if (modalVisiable && data && data.CUS) {
        form.setFieldsValue({
          CUS: data.CUS,
          CNAME: data.CNAME,
          location: data.location,
        });
      }
    }, 100);
  }, [modalVisiable]);

  useEffect(() => {
    // first load po info for if  modify
    if (state.po.itemID) {
      let p = data.items.filter((i: any) => i.id === state.po.itemID)[0];
      // fast render content.
      if (p) {
        setAddBtnLoading(true);
        setItemQty(p.QTY);
        setItemUNIT(p.UNIT);
        getProInfo(p.CODE, () => {
          form.setFieldsValue({
            CUS: data.CUS,
            CNAME: data.CNAME,
            PACKING: data.PACING,
            CODE: p.CODE,
            CBDESC: p.CBDESC,
            QTY: p.QTY,
            UP: p.UP,
            UNIT: p.UNIT,
            location: data.location,
            date_sequence:data.date_sequence,
            STOCK_LOC: p.STOCK_LOC,
            remark: data.remark,
          });
        });
      }
    }
  }, [state.po.itemID]);

  function getProInfo(code: string, fn?: () => void) {
    //讀取API product信息. fn option callback function
    if (!code) return;
    if (code.indexOf("`|`") > 0) {
      var code2 = code.split("`|`");
      code = code2[0];
    }

    form.setFieldsValue({
      CODE: code,
    });
    //getStock(code);
    axios("https://kfkproapi.kfkhk.com/api/goods/" + code, {
      method: "get",
      params: { abc: "34sfsdf34234sfsds34fsfe34f" },
    })
      .then((v) => {
        if (v.status === 200) {
          let playload = v.data.data[0];
          if (playload) {
            form.setFieldsValue({
              CODE: playload.CODE,
              CBDESC: playload.CBDESC,
              QTY: "",
            });
            setPro(playload);

            if (fn) {
              fn();
            }
            setAddBtnLoading(false);
          } else {
            message.warn("無資料!");
          }
        } else {
          message.warn("Server故障!");
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function genRetaiPriceList(pro: any) {
    // show 零售價格格式字符

    let str = "";
    pro.price
      // .filter((e: any) => {
      //   return e.RETAILUNIT === 1;
      // })
      .forEach((e: any) => {
        str += " $" + e.UP + "/" + e.UNIT + " , ";
      });
    if (str.length > 0) {
      str = str.substring(0, str.length - 2);
    }
    return str;
  }

  function genStockList(pro: any) {
    // show 倉數格式字符

    let str = "";
    pro.stock
      // .filter((e: any) => {
      //   return e.RETAILUNIT === 1;
      // })
      .forEach((e: any) => {
        str += e.LOC + ": " + e.PHY + e.UNIT + " , ";
      });
    if (str.length > 0) {
      str = str.substring(0, str.length - 2);
    }
    return str;
  }

  function updateOrAddOrder(v: any) {
    if (state.po.currentID === null) {
      // add new

      setAddBtnLoading(true);
      axios
        .post("/api/po", {
          CODE: v.CODE,
          CUS: v.CUS,
          CNAME: v.CNAME,
          location: v.location,         
          total: v.UP * v.QTY,
          remark: v.remark,
          user_name:state.login_state.user,
          items: [
            {
              CODE: v.CODE,
              CBDESC: v.CBDESC,
              PACKING: v.PACKING,
              QTY: v.QTY,
              UNIT: v.UNIT,
              RATIO_N: v.RATIO_N,
              RATIO_D: v.RATIO_D,
              UP: v.UP,
              TOTAL: v.UP * v.QTY,
              SHOP_GOODS_LOC: v.SHOP_GOODS_LOC,
              STOCK_LOC: v.STOCK_LOC,
              remark: v.remark,
            },
          ],
        })
        .then((v) => {
          if (v.status === 200) {
            if (v.data.success) {
              setModalVisiable(false);
              form.resetFields();
              dispatch(
                poAction({
                  pageNo: 1,
                  reloadFlag: !state.po.reloadFlag,
                  itemID: null,
                  currentID: v.data.data.id,
                  order_no: v.data.data.order_no,
                })
              );
              history.push("/main/podetail");
            } else {
              Modal.error({
                title: "Add record failed.",
                content: JSON.stringify(v.data.msg),
              });

              setAddBtnLoading(false);
            }
          }
        })
        .catch((e) => {
          Modal.error({ title: "Add record failed." });
          console.log(e);
          setAddBtnLoading(false);
        });
    } else if (state.po.currentID !== null) {
      // modify exist po order.

      if (state.po.itemID === null) {
        // add exist new item
        data.CUS = v.CUS;
        data.CNAME = v.CNAME;
        data.location = v.location;
        data.date_sequence=v.date_sequence;
        data.remark = v.remark;
        data.items.push({
          CODE: v.CODE,
          CBDESC: v.CBDESC,
          PACKING: v.PACKING,
          QTY: v.QTY,
          UNIT: v.UNIT,
          RATIO_N: v.RATIO_N,
          RATIO_D: v.RATIO_D,
          UP: v.UP,
          TOTAL: v.UP * v.QTY,
          SHOP_GOODS_LOC: v.SHOP_GOODS_LOC,
          STOCK_LOC: v.STOCK_LOC,
        });
      } else {
        // modify a item.
        data.CUS = v.CUS;
        data.CNAME = v.CNAME;
        data.location = v.location;
        data.date_sequence=v.date_sequence;
        data.remark = v.remark;
        data.items[data.items.findIndex((i: any) => i.id === state.po.itemID)] =
          {
            id: state.po.itemID,
            CODE: v.CODE,
            CBDESC: v.CBDESC,
            PACKING: v.PACKING,
            QTY: v.QTY,
            UNIT: v.UNIT,
            RATIO_N: v.RATIO_N,
            RATIO_D: v.RATIO_D,
            UP: v.UP,
            TOTAL: v.UP * v.QTY,
            SHOP_GOODS_LOC: v.SHOP_GOODS_LOC,
            STOCK_LOC: v.STOCK_LOC,
          };
      }

      // calculate total
      let t: number = 0;
      data.items.map((i: any) => {
        return (t = i.TOTAL + t);
      });
      data.total = t;

      setAddBtnLoading(true);
      axios
        .put("/api/po", data)
        .then((v) => {
          if (v.status === 200) {
            if (v.data.success) {
              //Modal.info({ title: "Add user success" });

              // Dont closed modal.

              // setModalVisiable(false);
              form.resetFields();
              // set cus code in form.
              if (state.po.currentID !== null) {
                form.setFieldsValue({
                  CUS: data.CUS,
                  location: data.location,
                  date_sequence:data.date_sequence
                });
              }
              dispatch(
                poAction({
                  pageNo: 1,
                  reloadFlag: !state.po.reloadFlag,
                  itemID: null,
                })
              );
              message.success("資料已經存儲。");
            } else {
              Modal.error({
                title: "Edit record failed.",
                content: JSON.stringify(v.data.msg),
              });
            }
            setAddBtnLoading(false);
          }
        })
        .catch((e) => {
          Modal.error({ title: "Edit record failed." });
          setAddBtnLoading(false);
        });
    }

    //clean item unit and qty input.
    setPro(null);
    setItemUNIT("");
    setItemQty("");
  }

  function loadProCode(code: string) {
    if (code.trim() === "") return;
    code = code.toUpperCase();
    axios("https://kfkproapi.kfkhk.com/api/goodsmulti/" + code, {
      method: "get",
      params: { abc: "34sfsdf34234sfsds34fsfe34f" },
    })
      .then((v) => {
        if (v.status === 200) {
          let playload = v.data.data;
          if (playload) {
            setSltCOde(playload);
          }
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }
  function loadCUS(code: string = "") {
    if (code.trim() === "") return;

    code = code.toUpperCase();
    axios("/api/cs", {
      method: "get",
      params: { searchContent: code },
    })
      .then((v) => {
        if (v.status === 200) {
          let playload = v.data.data;
          if (playload) {
            setCusCOde(playload);
          }
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function loadCSGoods() {
    let cus = form.getFieldValue("CUS");
    if (cus.trim() === "") return;

    axios("/api/csgoods", {
      method: "get",
      params: { CUS: cus },
    })
      .then((v) => {
        if (v.status === 200) {
          let playload = v.data.data;
          if (playload.length > 0) {
            setOriginGoodsData(playload);
            setGoodsData(playload);
          } else {
            message.warning("沒有資料.");
          }
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function setCUSCode(code: string, fn?: () => void) {
    //讀取API product信息. fn option callback function
    if (!code) return;
    let cname: string = "";
    if (code.indexOf("`|`") > 0) {
      var code2 = code.split("`|`");
      code = code2[0];
      cname = code2[1];
    }
    setCsName(cname);
    form.setFieldsValue({
      CUS: code,
      CNAME: cname,
    });
  }

  return (
    <>
      <Modal
        visible={modalVisiable}
        onCancel={() => {
          setModalVisiable(false);
          form.resetFields();
          setPro(null);
          setStock(null);
          dispatch(poAction({ itemID: null }));
          setAddBtnLoading(true);
          setCsName("");
          setItemUNIT("");
          setItemQty("");
        }}
        footer={false}
        destroyOnClose
        style={{ top: 5 }}
      >
        <div
          style={{
            display: "table",
            width: "100%",
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              <h1>
                {state.po.currentID !== null && state.po.itemID !== null
                  ? "採購修改產品"
                  : "採購加入產品"}
              </h1>
            </div>
          </div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              {state.po.currentID === null
                ? "新訂單"
                : "當前訂單:" + state.po.order_no}
            </div>
          </div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              <Form
                size="small"
                name="basic"
                style={{ marginLeft: 20, marginRight: 20 }}
                // initialValues={{  }}
                onFinish={updateOrAddOrder}
                labelCol={{ span: 3 }}
                form={form}
              >
                <Form.Item name="id" label="id" style={{ display: "none" }}>
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  name="RATIO_N"
                  label="RATIO_N"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  name="RATIO_D"
                  label="RATIO_D"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>
                {/* <Form.Item
                  name="PACKING"
                  label="PACKING"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item> */}
                <Form.Item name="UP" label="UP" style={{ display: "none" }}>
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  name="SHOP_GOODS_LOC"
                  label="SHOP_GOODS_LOC"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>
                {
                  // 入貨modal
                }
                <Modal
                  title="客戶貨品最後訂貨日期"
                  visible={showCSGoodList}
                  footer={false}
                  onOk={() => {
                    setShowCsGoodsList(false);
                    setOriginGoodsData([])
                    setGoodsData([]);
                  }}
                  onCancel={() => {
                    setShowCsGoodsList(false);
                    setOriginGoodsData([])
                    setGoodsData([]);
                  }}
                >
                  <Input
                    placeholder="查找產品編號"
                    onChange={(v) => {
                      if (v.currentTarget.value.trim()) {
                        let result = originGoodsData.filter(
                          (i) =>
                            i.CODE.indexOf(v.currentTarget.value.trim().toUpperCase()) >= 0
                        );
                        setGoodsData(result);
                      }
                    }}
                  ></Input>
                  <Divider></Divider>
                  <Timeline mode={"right"}>
                    {goodsData.map((e) => {
                      return (
                        <Timeline.Item label={e.date}>
                          <Button
                            type={"link"}
                            onClick={() => {
                              setItemQty("");
                              setItemUNIT("");
                              getProInfo(e.CODE + "`|`" + e.CBDESC);
                              setShowCsGoodsList(false);
                              setGoodsData([]);
                            }}
                          >
                            {e.CODE}
                          </Button>{" "}
                          {e.CBDESC}{" "}
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                </Modal>
                <b style={{ color: "blue" }}>{csName ? csName : data?.CNAME}</b>{" "}
                {(csName || data?.CNAME) && (
                  <Button
                    onClick={() => {
                      setShowCsGoodsList(true);
                      loadCSGoods();
                    }}
                  >
                    入貨記錄
                  </Button>
                )}
                <Form.Item
                  label="客戶"
                  name="CUS"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  <Select
                    ref={inputRef}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 280,
                    }}
                    onSearch={loadCUS}
                    showSearch
                    onChange={(vl: string) => {
                      setCUSCode(vl);
                      //setGoodsCSCode(vl.split("`|`")[0]);
                    }}
                    size="large"
                    allowClear
                  >
                    {cusCode.map((item) => (
                      <Select.Option
                        value={item["CUS"] + "`|`" + item["CNAME"]}
                        key={item["CUS"]}
                      >
                        {item["CUS"] + " " + item["CNAME"]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="CNAME"
                  label="CNAME"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  label="產品編號"
                  name="CODE"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  <Select
                    ref={inputRef}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 280,
                    }}
                    onSearch={loadProCode}
                    showSearch
                    onChange={(vl: string) => {
                      setItemQty("");
                      setItemUNIT("");
                      getProInfo(vl);
                    }}
                    size="large"
                    allowClear
                  >
                    {sltCode.map((item) => (
                      <Select.Option
                        value={item["CODE"] + "`|`" + item["CBDESC"]}
                        key={item["CODE"]}
                      >
                        {item["CODE"] + " " + item["CBDESC"]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="產品名稱"
                  name="CBDESC"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  <Input
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 280,
                    }}
                    size="large"
                    readOnly
                  ></Input>
                </Form.Item>
                <div
                  style={{
                    display: "table",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  {" "}
                  <div style={{ display: "table-row" }}>
                    {pro === null
                      ? ""
                      : (pro as any).price
                          .filter(
                            (p: any) => p.EXPIRED === 0 && p.ISINVUNIT === 1
                          )
                          .map((m: any, index: number) => {
                            if (m.PACKING) {
                              form.setFieldsValue({ PACKING: m.PACKING });
                            }
                            return (
                              <>
                                <div
                                  style={{
                                    display: "table-cell",
                                    width: "20%",
                                    textAlign: "right",
                                  }}
                                >
                                  {" "}
                                  <InputNumber
                                    style={{
                                      width: "60px",
                                      marginRight: "5px",
                                    }}
                                    onChange={(v: any) => {
                                      if (v < 1) {
                                        v = 1;
                                      }
                                      setItemQty(v);
                                      setItemUNIT(m.UNIT);
                                      form.setFieldsValue({
                                        UNIT: m.UNIT,
                                        QTY: v,
                                        RATIO_N: m.RATIO_N,
                                        RATIO_D: m.RATIO_D,
                                        UP: m.UP,
                                      });
                                    }}
                                    // value={data.items.filter((i:any)=>i.id===state.po.itemID)[0]?.UNIT===m.UNIT?data.items.filter((i:any)=>i.id===state.po.itemID)[0]?.QTY:itemQty}
                                    value={itemUnit === m.UNIT ? itemQty : ""}
                                  ></InputNumber>
                                </div>
                                <div
                                  style={{
                                    display: "table-cell",
                                    width: "10%",
                                    textAlign: "left",
                                  }}
                                >
                                  {m.UNIT}
                                </div>
                              </>
                            );
                          })}
                  </div>
                </div>
                <Form.Item
                  label="包装"
                  name="PACKING"
                  rules={[{ required: true }]}
                  hasFeedback
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="單位"
                  name="UNIT"
                  rules={[{ required: true }]}
                  style={{ display: "none" }}
                  hasFeedback
                >
                  <InputNumber size="large" />
                </Form.Item>
                <Form.Item
                  label="訂購數量"
                  name="QTY"
                  rules={[{ required: true }]}
                  style={{ display: "none" }}
                  hasFeedback
                >
                  <InputNumber size="large" />
                </Form.Item>
                <Form.Item
                  label="地點"
                  name="location"
                  rules={[{ required: true }]}
                  hasFeedback
                >
                  <Select>
                    <Select.Option key="1" value="香港">
                      香港
                    </Select.Option>
                    <Select.Option key="2" value="國內">
                      國內
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="序號" name="date_sequence" hidden={state.po.date_sequence?true:false}>
                  <Input></Input>
                </Form.Item>
                <Form.Item label="備註" name="remark" hasFeedback>
                  <Input></Input>
                </Form.Item>
              </Form>
              <div
                style={{
                  display: "table",
                  marginLeft: 20,
                  marginBottom: 20,
                  padding: 3,
                }}
              >
                <div style={{ display: "table-row" }}>
                  <div style={{ display: "table-cell", fontSize: "14pt" }}>
                    倉數:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      fontSize: "14pt",
                      color: "blue",
                    }}
                  >
                    {pro === null ? "" : genStockList(pro)}
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div style={{ display: "table-cell", fontSize: "14pt" }}>
                    批發價格:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      fontSize: "14pt",
                      color: "blue",
                    }}
                  >
                    {pro === null ? "" : genRetaiPriceList(pro)}
                  </div>
                </div>
                {/* <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 5,
                      fontSize: "14pt",
                    }}
                  >
                    安全倉數:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 5,
                      fontSize: "14pt",
                    }}
                  >
                    {pro === null ? "" : genStockUnitAndPacking(pro)}
                  </div>
                </div> */}
              </div>

              <Button
                type="primary"
                size="large"
                style={{ marginRight: 16 }}
                loading={addbtnLoading}
                onClick={() => form.submit()}
              >
                {state.po.currentID !== null && state.po.itemID !== null
                  ? "修改"
                  : "加入"}
              </Button>
              <Button
                style={{ marginRight: 16 }}
                size="large"
                onClick={() => {
                  let vl: { CODE: "" } = form.getFieldsValue();
                  if (vl.CODE) {
                    getProInfo(vl.CODE);
                    // getStock(vl.CODE);
                  } else {
                    getProInfo("PD018-6050B");
                    //  getStock("PD018-6050B");
                  }
                }}
              >
                查找
              </Button>
              <Upload
                accept="image/*;capture=camera"
                multiple={false}
                showUploadList={false}
                beforeUpload={(file: any, FileList: any[]) => {
                  // console.log(file);
                  // console.log(FileList);
                  const reader = new FileReader();

                  reader.onload = (e: any) => {
                    scanBarcode(e.target.result, getProInfo);
                  };
                  reader.readAsDataURL(file);

                  // scanBarcode(file)
                  return false;
                }}
              >
                {/* <Button size="large" icon={<CameraOutlined />}>
                  相機
                </Button> */}
              </Upload>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
