// state.po.itemID not null that mean modify order items
// state.po.currentID null that mean add new order.
// back to order list need clear state.po.currentID and state.po.itemID and state.po.order_no

import React, { useEffect, useState } from "react";
import { Button, List, Tabs } from "antd";
import { getDataForTable } from "../../Utils/getDataForTable";
import { CheckOutlined } from "@ant-design/icons";
import { useStateContext } from "../../Store/StateProvider";
import { defaultStateType } from "../../Store/defaultStatus";
import { useHistory } from "react-router-dom";
import { poAction } from "../../Reducers/po_reducer";
import { genDatetimeFormat } from "../../Utils/genDateTimeFormat";
const { TabPane } = Tabs;

export default function ListOrder(props: any) {
  let [data, setData] = useState([]);
  let [pageTotal, setPageTotal] = useState();
  let [state, dispatch] = useStateContext();
  let history = useHistory();

  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
  ] = Object.values(state.po);

  useEffect(() => {
    getDataForTable(
      {
        pageSize,
        pageNo,
        searchField,
        searchContent,
        between,
        conditionType,
        queryType,
        orderBy,
        sortBy,
      },
      setData,
      setPageTotal,
      "/api/pos",
      "GET"
    );
  }, [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
    state.po.reloadFlag,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {

      getDataForTable(
        {
          pageSize,
          pageNo,
          searchField,
          searchContent,
          between,
          conditionType,
          queryType,
          orderBy,
          sortBy,
        },
        setData,
        setPageTotal,
        "/api/pos",
        "GET"
      );
    }, 60000);
    return ()=>clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        display: "table",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {" "}
      <Tabs
        defaultActiveKey={(() => {
          switch (state.po.searchContent) {
            case "香港":
              return "1";
            case "國內":
              return "2";
            default:
              return "0";
          }
        })()}
        type="card"
        onChange={(k) => {
          switch (k) {
            case "1":
              dispatch(
                poAction({
                  searchField: "location",
                  searchContent: "香港",
                  pageNo: 1,
                })
              );
              break;
            case "2":
              dispatch(
                poAction({
                  searchField: "location",
                  searchContent: "國內",
                  pageNo: 1,
                })
              );
              break;
            default:
              dispatch(poAction({ searchField: "", searchContent: "" }));
          }
        }}
      >
        <TabPane tab="全部" key="0"></TabPane>
        <TabPane tab="香港" key="1"></TabPane>
        <TabPane tab="國內" key="2"></TabPane>
      </Tabs>
      <div style={{ display: "table-row" }}>
        <div style={{ display: "table-cell", textAlign: "center" }}>
          <List
            style={{ maxHeight: 580, height: 575, overflow: "hidden" }}
            pagination={{
              onChange: (page) => {
                dispatch(
                  poAction({
                    pageNo: page,
                    reloadFlg: !(data as defaultStateType).order?.reloadFlag,
                  })
                );
              },
              current: pageNo,
              total: pageTotal,
              pageSize: pageSize,
              defaultPageSize: 6,
            }}
            size="large"
            bordered
            dataSource={data}
            renderItem={(item: any) => (
              <List.Item>
                <div style={{ display: "table", width: "100%" }}>
                  <div
                    style={{
                      display: "table-cell",
                      verticalAlign: "top",
                      width: "20%",
                    }}
                  >
                    {item.date_sequence}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      verticalAlign: "top",
                      width: "30%",
                    }}
                  >
                    {genDatetimeFormat("-d", item.order_date)}
                    <br />

                    {item.user_name}
                    {item.location}
                  </div>{" "}
                  <div
                    style={{
                      display: "table-cell",
                      verticalAlign: "top",
                      width: "20%",
                      textAlign: "left",
                    }}
                  ></div>{" "}
                  <div
                    style={{
                      display: "table-cell",
                      verticalAlign: "top",
                      width: "40%",
                      textAlign: "left",
                    }}
                  >
                    {item.CUS.trim()}
                    {item.CNAME.trim()}
                  </div>{" "}
                  <div style={{ display: "table-cell" }}>
                    {item.finished ? (
                      <div style={{ display: "inline-block" }}>
                        <Button
                          type="text"
                          onClick={() => {
                            dispatch(
                              poAction({
                                currentID: item.id,
                                order_no: item.order_no,
                              })
                            );
                            history.push("/main/podetail");
                          }}
                          icon={<CheckOutlined style={{ color: "green" }} />}
                        >
                          查看
                        </Button>
                        <br />
                        {item.printed && (
                          <Button
                            type="text"
                            onClick={() => {
                              dispatch(
                                poAction({
                                  currentID: item.id,
                                  order_no: item.order_no,
                                })
                              );
                              history.push("/main/podetail");
                            }}
                            icon={<CheckOutlined style={{ color: "green" }} />}
                          >
                            已印
                          </Button>
                        )}
                      </div>
                    ) : (
                      <Button
                        type="dashed"
                        onClick={() => {
                          dispatch(
                            poAction({
                              currentID: item.id,
                              order_no: item.order_no,
                            })
                          );
                          history.push("/main/podetail");
                        }}
                      >
                        修改
                      </Button>
                    )}
                  </div>{" "}
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
}
