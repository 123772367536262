import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { useStateContext } from "../../Store/StateProvider";
import { useHistory } from "react-router-dom";
import { getDataForTable } from "../../Utils/getDataForTable";
import { poAction } from "../../Reducers/po_reducer";
import axios from "axios";
import "./print_hight.css";
import { genDatetimeFormat } from "../../Utils/genDateTimeFormat";

export default function PoDetailPrint(props: any) {
  let [modalVisiable, setModalVisiable] = useState(false);
  let [state, dispatch] = useStateContext();
  let history = useHistory();
  let [data, setData] = useState({
    order_date: "",
    CUS: "",
    user: { user_name: "" },
    total: 0,
    remark: "",
    location: "",
    date_sequence:0,
    items: [],
    finished: false,
  });
  let [, setPageTotal] = useState();
  let [finishBtnLoading, setFinishBtnLoading] = useState(false);
  let [cusname, steCusname] = useState();
  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
  ] = Object.values(state.po);
  useEffect(() => {
    getDataForTable(
      {
        pageSize,
        pageNo,
        searchField: "id",
        searchContent: state.po.currentID,
        between,
        conditionType,
        queryType,
        orderBy,
        sortBy,
      },
      setData,
      setPageTotal,
      "/api/po",
      "GET"
    );
    setTimeout(() => {
      // data.items=data.items.sort((s:any)=>s.CODE)
      window.print();
    }, 1000);
    // return () => dispatch(orderAction({ currentID: null }));
  }, [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
    state.po.reloadFlag,
  ]);

  useEffect(() => {
    if (data.items.length > 1) {
      data.items = data.items.sort((a: any, b: any) => a.CODE - b.CODE);
    }
  }, [data.items]);

  useEffect(() => {
    if (data.CUS !== "") {
      axios
        .get("/api/cs", {
          params: {
            searchField: "CUS",
            searchContent: data.CUS,
          },
        })
        .then((v) => {
          steCusname(v.data.data[0].CNAME);
        });
    }
  }, [data.CUS]);

  function finishOrder() {
    axios
      .put("/api/po", { id: state.po.currentID, finished: true })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            //Modal.info({ title: "Add user success" });
            setModalVisiable(false);

            dispatch(
              poAction({
                pageNo: 1,
                reloadFlag: !state.po.reloadFlag,
                itemID: null,
              })
            );
          } else {
            Modal.error({
              title: "Edit record failed.",
              content: JSON.stringify(v.data.msg),
            });
          }
          setFinishBtnLoading(false);
        }
      })
      .catch((e) => {
        Modal.error({ title: "Edit record failed." });
        setFinishBtnLoading(false);
      });
  }

  let colums = [
    {
      title: "編號",
      dataIndex: "CODE",
    },
    {
      title: "產品名",
      dataIndex: "CBDESC",
    },
    {
      title: "包装",
      dataIndex: "PACKING",
    },
    {
      title: "數量",
      dataIndex: "QTY",
    },
    {
      title: "單位",
      dataIndex: "UNIT",
    },
    {
      title: "單價",
      dataIndex: "RETAILUP",
      render: (v: any) => {
        return "$" + v;
      },
    },
    {
      title: "倉存",
      dataIndex: "PRO",
      render: (val: any) => {
        return val && val.SAVE_STOCK;
      },
    },
  ];

  if (!state.po.print_show_price) {
    colums.splice(5, 1);
    if (!state.po.print_show_stock) {
      colums.splice(5, 1);
    }
  } else {
    if (!state.po.print_show_stock) {
      colums.splice(6, 1);
    }
  }
  //   if(!state.po.print_show_stock){
  //     colums.splice(5,1)
  //  }

  return (
    <>
      <div
        style={{
          display: "table",
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          maxWidth: 800,
          marginTop: 0,
        }}
      >
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell", textAlign: "center" }}>
            <h3>
              採購單:{genDatetimeFormat("-d", data.order_date)+'-'+data.date_sequence+data.location}
              {" ("}
              {data.user.user_name}
              {")"}
            </h3>
          </div>{" "}
          <div style={{ display: "table-cell", textAlign: "center" }}>
            <h3>日期:{genDatetimeFormat("-", data.order_date)}</h3>
          </div>{" "}
        </div>
        <div style={{ display: "table-row", textAlign: "center" }}>
          <div style={{ display: "table-cell", textAlign: "center" }}>
            <h3>{state.po.order_no} </h3>
          </div>
          <div style={{ display: "table-cell", textAlign: "center" }}>
            <h3> {data.CUS}{cusname}</h3>
          </div>
        </div>
      </div>

      <Table columns={colums} dataSource={data.items} pagination={false} />
      <div
        style={{
          display: "table",
          width: "100%",
          bottom: 0,
          maxWidth: 800,
        }}
      >
        {data.remark}
      </div>
      <div
        style={{
          display: "table",
          width: "100%",
          bottom: 0,
          maxWidth: 800,
        }}
      >
        {data.location}
      </div>
      <div
        style={{
          display: "table",
          width: "100%",
          position: "fixed",
          bottom: 0,
          maxWidth: 800,
        }}
      >
        <div style={{ display: "table-row", padding: 10 }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              padding: 10,
              height: 80,
            }}
          >
            <Button
              className="no_print"
              type="primary"
              size="large"
              onClick={() => {
                //dispatch(poAction({ currentID: null, order_no: "" }));
                history.push("/main/podetail");
              }}
            >
              {data.finished ? "關閉" : "暫存"}
            </Button>
            {data.finished && (
              <Button
                type="dashed"
                danger
                size="large"
                style={{ marginLeft: 16 }}
                onClick={() => {
                  window.print();
                }}
                className="no_print"
              >
                列印
              </Button>
            )}
          </div>
          {data.finished ? (
            ""
          ) : (
            <div
              style={{
                display: "table-cell",
                textAlign: "center",
                padding: 10,
                height: 80,
              }}
            >
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  setModalVisiable(true);
                }}
              >
                加入產品
              </Button>
            </div>
          )}
          {data.finished ? (
            ""
          ) : (
            <div
              style={{
                display: "table-cell",
                textAlign: "center",
                padding: 10,
                height: 70,
              }}
            >
              <Button
                disabled={finishBtnLoading}
                type="primary"
                size="large"
                onClick={() => {
                  setFinishBtnLoading(true);
                  finishOrder();
                }}
              >
                完成
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
