import React, { useEffect } from "react";
import { Route, withRouter, useLocation } from "react-router-dom";
import axios from "axios";
import Loading from "./Loading";
import { useStateContext } from "../Store/StateProvider";
import Login from "./Login";
import ErrorPage from "./Error";
import { setLoginStateAction } from "../Reducers/login_reducer";
import Main from "./Main";
import PoDetailPrint from "./Po/PoDetailPrint";

const Layout = (props: any) => {
  let [state, dispatch] = useStateContext();
  let location = useLocation();

  //fist time check
  useEffect(() => {
    console.log("Layout effect.");
    //login state is object {state:'true:false,loading,error',user:'admin'}
    axios
      .get("/api/check_login")
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success === true) {
            dispatch(
              setLoginStateAction({
                state: "true",
                user: v.data.data.user_name,
                group: v.data.data.group,
              })
            );
            if (location.pathname === "/") {
              props.history.push("/main");
            } else {
              props.history.push(location.pathname);
            }

            console.log("Layout page dispatch setLoginStateAction");
          } else {
            dispatch(setLoginStateAction({ state: "false" }));
            props.history.push("/login");
            console.log("2");
          }
        } else {
          dispatch(setLoginStateAction({ state: "error" }));
          props.history.push("/error");
          console.log(state.login_state.state);
        }
      })
      .catch((e) => {
        dispatch(setLoginStateAction({ state: "error" }));
        props.history.push("/error");
        console.log(state.login_state.state);
      });
  }, [props.history]);

  return (
    <div style={{ margin: "auto", width: "100%", maxWidth: 800 }}>
      {/* <Route path="/" exact>
        <Redirect to="/main"></Redirect>
      </Route> */}
      <Route path="/" exact component={Loading} />
      <Route path="/login" component={Login} />
      <Route path="/error" component={ErrorPage} />
      <Route path="/main" component={Main} />
      <Route path="/loading" component={Loading} />
      <Route path="/poprint" component={PoDetailPrint} />
    </div>
  );
};

export default withRouter(Layout);
